
    import {
      DeleteOutlined,
      DownloadOutlined,
      CopyOutlined
    } from '@ant-design/icons-vue';
    import {
      renderUnit
    } from "@/utils/common"
    import _ from "lodash"
    import config from "./detail-config"
    import XLSX from 'xlsx';
  
    export default {
      name: "test",
      components: {
        DeleteOutlined,
        DownloadOutlined,
        CopyOutlined
      },
      emits: [
        'done',
        'update:visible'
      ],
      props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object
      },
      data() {
        return {
          // 提交状态
          loading: false,
          // 弹窗标题
          title: '',
          // 订单信息
          order: {},
          table_packages: [{
              key: 'index',
              width: 48,
              align: 'center',
              customRender: ({
                index
              }) => index + 1
            },
            {
              title: this.$t('user_order.fields.dims'),
              dataIndex: 'length',
              width: 120,
              ellipsis: true,
              customRender: ({
                record
              }) => `${record.length} x ${record.width} x ${record.height} ${this.renderUnit(record, 'dims_unit')}`
            },
            {
              title: this.$t('share.weight'),
              dataIndex: 'weight',
              width: 100,
              customRender: ({
                record
              }) => {
                return `${record.weight} ${this.renderUnit(record, 'weight_unit')}`
              }
            },
            {
              title: this.$t('user_order.fields.reference'),
              dataIndex: 'reference1',
              slots: {
                customRender: 'reference'
              }
            },
            {
              title: this.$t('user_order.fields.pieces'),
              dataIndex: 'pieces',
              width: 100
            },
            {
              title: this.$t('user_order.fields.charge'),
              dataIndex: 'charge',
              width: 120,
              customRender: ({
                record
              }) => `${record.invoice_charge || record.charge} ${record.currency}`
            },
            {
              title: this.$t('user_order.fields.master_tracking_number'),
              dataIndex: 'tracking_number',
              width: 200,
              slots: {
                customRender: 'tracking_number'
              }
            }
          ],
          table_charges: [{
              key: 'index',
              width: 48,
              align: 'center',
              customRender: ({
                index
              }) => index + 1
            },
            {
              title: this.$t('user_order.fields.master_tracking_number'),
              dataIndex: 'package_trakcing_number',
              width: 200,
              customRender: ({
                record
              }) => {
                return record.package_trakcing_number || record.order_tracking_number
              }
            },
            {
              title: this.$t('account.fields.surcharge_charge_name'),
              dataIndex: 'charge_name',
              width: 200
            },
            {
              title: this.$t('share.remark'),
              dataIndex: 'remark'
            },
            {
              title: this.$t('user_order.fields.charge'),
              dataIndex: 'charge',
              width: 120,
              customRender: ({
                record
              }) => `${[1, 3].includes(record.record_type) ? '+' : '-'}${record.charge} ${record.currency}`
            },
            {
              title: this.$t('account.fields.created'),
              dataIndex: 'created',
              width: 150
            }
          ]
        };
      },
      computed: {
        packages() {
          return this.order.packages || [];
        },
        charges() {
          return this.order.charges || [];
        },
        logs() {
          return this.order.logs || [];
        },
        shipper_address() {
          return this.order.shipper_address || {};
        },
        address() {
          return this.order.address || {};
        },
        items() {
          return this.order.items || [];
        },
        /* 系统配置 */
        system_config() {
          return this.$store.state.system.config
        },
        invoice_charge_computed() {
          if (!this.order) {
            return ""
          }
  
          return `${this.order.invoice_charge} ${this.order.currency}`
        },
        /* order_address */
        order_address() {
          let str = this.address.address1 || ''
          if (this.address.address2) {
            str += " " + this.address.address2
          }
  
          if (this.address.address3) {
            str += " " + this.address.address3
          }
          return str
        },
        order_shipper_address() {
          let str = this.shipper_address.address1 || ''
          if (this.shipper_address.address2) {
            str += " " + this.shipper_address.address2
          }
          if (this.shipper_address.address3) {
            str += " " + this.shipper_address.address3
          }
  
          return str
        },
      },
      watch: {
        data() {
          this.title = this.data.po_number;
  
          this.load_order();
        }
      },
      mounted() {
        this.data && this.load_order();
      },
      methods: {
        /* 复制 */
        copyText() {
          const text = this.packages.map((p) => p.tracking_number).join();
          const input = document.createElement("input"); // 创建一个输入框元素
          input.value = text; // 将要复制的文本内容赋值给输入框元素的 value 属性
          document.body.appendChild(input); // 将输入框元素添加到文档体中
          input.select(); // 选中输入框元素的内容
          document.execCommand("copy"); // 执行复制操作
          document.body.removeChild(input); // 将输入框元素从文档体中移除
          this.$message.success('复制成功');
        },
  
  
        // 导出Excel-包裹
        exportPackageExcel() {
          const array = [
            ["尺寸", "重量", "Reference1", "Reference2", "物品数量", "费用", "跟踪号"]
          ];
          this.packages.forEach((p) => {
            array.push([`${p.length} x ${p.width} x ${p.height} ${p.dims_unit}`, `${p.calc_weight} ${p.weight_unit}`,
              p.reference1, p.reference2, p.pieces, `${p.charge} ${p.currency}`, p.tracking_number
            ])
          })
          const sheet = XLSX.utils.json_to_sheet(array, {
            skipHeader: true
          });
  
          sheet['!cols'] = [{
            wpx: 100
          }, {
            wpx: 100
          }, {
            wpx: 100
          }, {
            wpx: 100
          }, {
            wpx: 100
          }, {
            wpx: 100
          }, {
            wpx: 100
          }]
          this.$util.exportSheet(XLSX, sheet, `${this.data.po_number}-包裹信息`);
        },
        load_order() {
          this.loading = true;
  
          this.$http.get(`/user/order/get_order_info/${this.data.id}?get_more=1`).then(res => {
            this.loading = false;
            if (res.data.code === 0) {
              this.order = res.data.data;
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        },
        /* 更新visible */
        updateVisible(value) {
          this.$emit('update:visible', value);
        },
        onClose() {
          this.updateVisible(false);
        },
        /* 取消订单 */
        voidOrder: _.debounce(function() {
          const hide = this.$message.loading('share.requesting', 0);
  
          this.$http.post('/user/order/void', {
            ids: [this.data.id]
          }).then(res => {
            hide();
            if (res.data.code === 0) {
              this.$message.success(res.data.msg);
              this.load_order();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            hide();
            this.$message.error(e.message);
          });
        }, 300),
        /* 下载Label */
        downloadLabel(key) {
          const hide = this.$message.loading('share.requesting', 0);
  
          this.$http.post(`/user/order/${key}`, {
            ids: [this.data.id]
          }).then(res => {
            hide();
            if (res.data.code === 0) {
              const blob = this.base64ToBlob(res.data.data, 'application/pdf');
              const fileName = `${this.data.po_number}.pdf`;
  
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const el = document.createElement("a");
  
                el.download = fileName;
                el.style.display = "none";
                el.href = URL.createObjectURL(blob);
                document.body.appendChild(el);
  
                el.click();
  
                URL.revokeObjectURL(el.href);
                document.body.removeChild(el);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            } else {
              this.$message.error(`${res.data.msg}`);
            }
          }).catch(e => {
            hide();
            this.$message.error(e.message);
          });
        },
        base64ToBlob(text, content_type) {
          var byteString = atob(text);
          var arrayBuffer = new ArrayBuffer(byteString.length);
          var intArray = new Uint8Array(arrayBuffer);
  
          for (var i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
          }
  
          return new Blob([intArray], {
            type: content_type
          });
        }
      },
      setup() {
        const {
          columns
        } = config(),
          table_items = columns
        return {
          renderUnit,
          table_items
        }
      }
    }
